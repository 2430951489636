html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

button {
  border: none;
  background: none;
}

@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
:root {
  font-family: "Raleway", sans-serif;
  font-size: 24px;
  background-color: var(--main-color);
  --main-color: #f7f7f7;
  --header-text: #55be66;
  --highlight-color: #ffffff;
  --hero-bg: #212121b2;
  --footer-bg: #080808b2;
}
/* NAV */
.nav {
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 3rem;
}

.nav-title-container {
  text-align: center;
  color: var(--header-text);
}

.nav-title-container > h1 {
  font-size: 2rem;
  line-height: 1.8rem;
  font-weight: 900;
}

.nav-title-container > h2 {
  font-size: 0.325rem;
  letter-spacing: 0.03rem;
  font-weight: 700;
}

.nav-btn-container {
  padding: 1rem 0;
}

.nav-btn {
  font-size: 0.6rem;
  font-weight: 500;
  margin: 0 2rem;
  background: none;
  border: none;
  transition: 0.1s;
  padding: 0.5rem 0.8rem;
}

.nav-btn:hover {
  box-shadow: 0 0.1rem 0 var(--header-text);
  transform: translateY(-0.1rem);
}

/* NAV */
/* MAIN */
main {
  display: grid;
  grid-template: 75vh 50vh 90vh 1fr 100vh/ 1fr 1fr;
  grid-template-areas:
    "hero hero"
    "about about"
    "service service"
    "gallery gallery"
    "contact contact";
}
/* MAIN */
/* HERO */

.hero-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--hero-bg);
  grid-area: hero;
  color: var(--main-color);
  width: 100%;
  height: 80vh;
}

.hero-content {
  font-family: "Montserrat", sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: 70%;
  width: 60%;
  text-align: center;
}

.hero-content > h2 {
  font-family: "Poppins", sans-serif;
  font-size: 2rem;
  font-weight: 700;
}

.hero-content > p {
  line-height: 2;
  font-weight: 300;
}

.hero-content > a > button {
  background-color: var(--header-text);
  color: var(--main-color);
  padding: 0.5rem 1rem;
  font-weight: 600;
  letter-spacing: 0.2rem;
  transition: 0.05s;
}

.hero-content > a > button:hover {
  scale: 1.03;
  background-color: #4ca85b;
}

.hero-bg {
  grid-area: hero;
  background-size: 100%;
  z-index: -1;
  position: absolute;
  top: 3rem;
  width: 100vw;
  height: 80vh;
}

/* HERO */
/* ABOUT */
.about-container {
  display: flex;
  margin: 0 auto;
  grid-area: about;
  background-color: var(--highlight-color);
  width: 65vw;
  height: 50vh;
}

.about-container > img {
  height: 100%;
  aspect-ratio: 4 / 4;
  object-fit: cover;
}

.about-content {
  margin: auto;
  width: 50%;
}

.about-content > h2 {
  font-family: "Poppins", sans-serif;
  margin-bottom: 2rem;
  font-size: 1vw;
  font-weight: 700;
  letter-spacing: 0.2rem;
}

.about-content > p {
  line-height: 1.4rem;
  font-size: 1vw;
}

.background-design {
  margin: auto;
  z-index: -1;
  grid-area: about;
  width: 95%;
  opacity: 0.5;
}

/* ABOUT */
/* SERVICES */
.services-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-area: service;
  margin: 5vh auto auto auto;
  width: 70%;
  height: 100%;
}

.service {
  display: flex;
  flex-direction: column;
  margin: auto;
  align-items: center;
  width: 100%;
}

.service > img {
  width: 4rem;
  opacity: 40%;
  padding-bottom: 2rem;
}
.service > h3 {
  font-family: "Poppins", sans-serif;
  font-size: 0.8rem;
}
/* SERVICES */
/* GALLERY */

.gallery-title {
  grid-area: title;
  font-size: 0.8rem;
  margin: 2vh auto auto auto;
  font-weight: 700;
  letter-spacing: 0.8rem;
}

.gallery-container {
  grid-area: gallery;
  display: grid;
  align-items: center;
  grid-template: 0.2fr 1fr 0.2fr/ 1fr 1fr 1fr;
  grid-template-areas:
    "title title title"
    "img img img"
    "btn btn btn";
  height: 80%;
  width: 95%;
  margin: auto;
  gap: 2%;
}
.gallery-container > img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  transition: 0.1s;
  filter: brightness(0.7);
}

.gallery-container > img:hover {
  scale: 1.1;
  filter: brightness(1);
}
.gallery-btn {
  margin: auto;
  grid-area: btn;
}
.gallery-btn > button {
  padding: 0.4rem 1rem;
  color: var(--highlight-color);
  font-weight: 600;
  background-color: var(--header-text);
  transition: 0.05s;
}

.gallery-btn > button:hover {
  scale: 1.05;
  background-color: #4ca85b;
}
/* GALLERY */
/* CONTACT */
.form-container {
  grid-area: contact;
  margin: auto;
  height: 70%;
  width: 50%;
}

.form-container > h2 {
  text-align: center;
  margin-bottom: 5vh;
  font-size: 0.8rem;
  font-weight: 700;
  letter-spacing: 0.8rem;
}

.contact-form {
  font-size: 0.8rem;
  display: grid;
  grid-template: 0.7fr 4fr 1fr/ 1fr 1fr;
  grid-template-areas:
    "name email"
    "message message"
    "button button";
  gap: 1rem;
}

.contact-name,
.contact-email {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.contact-name {
  grid-area: name;
}

#name:focus,
#email:focus {
  width: 90%;
  border: none;
  padding-left: 0.3rem;
  height: 1.5rem;
}

#name,
#email {
  width: 100%;
  border: none;
  height: 1.5rem;
}

#email {
  margin-left: auto;
}

.contact-email {
  grid-area: email;
}

.contact-message {
  grid-area: message;
}

.contact-email > label,
.contact-name > label {
  margin: 0.4rem 0;
}

#message {
  resize: none;
  width: 100%;
  height: 100%;
  border: none;
  font-size: 0.7rem;
}
.submit-button {
  grid-area: button;
  margin: auto 0 auto auto;
}
.submit-button > button {
  background-color: var(--header-text);
  color: var(--highlight-color);
  border: none;
  padding: 0.2rem 0.8rem;
  font-weight: 700;
  font-size: 0.7rem;
}

.submit-button > button:hover {
  background-color: #4ca85b;
}
/* CONTACT */
/* FOOTER */

footer {
  background-color: var(--footer-bg);
  width: 100vw;
  padding: 1vw 0;
  margin-top: auto;
}

footer > section {
  display: flex;
  flex-direction: column;
  width: 40%;
  height: 20vh;
  margin: 0 auto;
}

.footer-info {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  color: var(--highlight-color);
  text-align: center;
  font-size: 0.8vw;
  font-weight: 300;
  font-family: "Poppins", sans-serif;
  height: 80%;
}

.sm-links {
  margin: auto;
}
.social-media-link {
  width: 2rem;
  filter: invert();
}
/* FOOTER */

@media (orientation: portrait) {
  @media (max-width: 961px) {
    main {
      display: grid;
      grid-template: 0.3fr 0.3fr 0.3fr 1fr 0.4fr/ 1fr 1fr;
      grid-template-areas:
        "hero hero"
        "about about"
        "service service"
        "gallery gallery"
        "contact contact";
    }

    .nav-btn-container {
      display: none;
    }

    .hero-bg {
      grid-area: hero;
      background-size: 150% 100%;
      background-repeat: no-repeat;
      background-position-x: center;
      z-index: -1;
      position: absolute;
      top: 3rem;
      height: 30rem;
      object-fit: fill;
    }

    .hero-container {
      height: 100%;
    }
    .hero-content {
      width: 80%;
    }

    .hero-content > h2 {
      font-size: 6vw;
    }

    .hero-content p {
      font-size: 2.8vw;
      font-weight: 500;
    }

    .hero-content > a > button {
      padding: 0.4rem 0.8rem;
      font-weight: 500;
      letter-spacing: 0.1rem;
    }

    .about-container > img {
      display: none;
    }
    .background-design {
      display: none;
    }
    .about-container {
      width: 100%;
      height: 100%;
      text-align: center;
    }
    .about-content {
      width: 70%;
    }
    .about-content > h2 {
      font-size: 2.5vw;
    }

    .about-content > p {
      font-size: 3vw;
      line-height: 1.2rem;
    }

    .service > img {
      width: 3rem;
      padding-bottom: 2rem;
    }
    .service > h3 {
      text-align: center;
      font-family: "Poppins", sans-serif;
      font-size: 0.5rem;
    }

    .gallery-title {
      grid-area: title;
      font-size: 0.5rem;
      margin: auto;
      font-weight: 700;
      letter-spacing: 0.2rem;
    }

    .gallery-container {
      grid-area: gallery;
      display: grid;
      align-items: center;
      grid-template: 0.2fr 1fr 1fr 1fr 0.2fr/ 1fr;
      grid-template-areas:
        "title"
        "img"
        "img"
        "img"
        "btn";
      height: 80%;
      width: 95%;
      margin: auto;
      gap: 2%;
    }
    .gallery-container > img {
      height: 100vw;
      width: 95vw;
      object-fit: cover;
      transition: 0;
      filter: brightness(1);
    }
    .gallery-container > img:hover {
      scale: 1;
    }
    .form-container {
      grid-area: contact;
      margin: 8rem auto;
      height: 70%;
      width: 80%;
    }

    .form-container > h2 {
      text-align: center;
      margin: 5vh auto;
      font-size: 0.5rem;
      font-weight: 700;
      letter-spacing: 0.2rem;
    }

    .contact-email > label,
    .contact-name > label {
      font-size: 0.6rem;
      text-align: center;
    }

    .submit-button {
      margin: auto;
    }

    .footer-info {
      text-align: center;
      font-size: 0.5rem;
      font-weight: 300;
      font-family: "Poppins", sans-serif;
      height: 80%;
    }
  }
}

@media (orientation: landscape) {
  @media (max-height: 576px) {
    .hero-content > h2 {
      font-size: 6vh;
    }

    .hero-content p {
      font-size: 2.8vh;
      font-weight: 500;
    }

    .hero-content > a > button {
      padding: 0.4rem 0.8rem;
      font-weight: 500;
      letter-spacing: 0.1rem;
    }
    .about-container > img {
      display: none;
    }
    .background-design {
      display: none;
    }
    .about-container {
      width: 100%;
      height: 100%;
      text-align: center;
    }
    .about-content {
      width: 70%;
    }
    .about-content > h2 {
      font-size: 2.5vh;
    }

    .about-content > p {
      font-size: 3vh;
      line-height: 1rem;
    }
    .service > img {
      width: 3rem;
      padding-bottom: 2rem;
    }
    .service > h3 {
      text-align: center;
      font-family: "Poppins", sans-serif;
      font-size: 0.5rem;
    }
    .gallery-title {
      grid-area: title;
      font-size: 0.5rem;
      margin: auto;
      font-weight: 700;
      letter-spacing: 0.2rem;
    }
    .form-container > h2 {
      text-align: center;
      margin-bottom: 5vh;
      font-size: 0.5rem;
      font-weight: 700;
      letter-spacing: 0.2rem;
    }

    footer {
      margin-top: 100%;
    }

    footer > section {
      height: 20vw;
      margin: 0 auto;
    }
    .footer-info {
      font-size: 2vw;
      font-weight: 300;
      font-family: "Poppins", sans-serif;
      height: 100%;
    }
  }
}
